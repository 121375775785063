module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1080},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"joel-for-ohio","short_name":"Joel for Ohio","start_url":"/","background_color":"#1e1f57","theme_color":"#1e1f57","display":"minimal-ui","icon":"src/images/circle-logo_color_sm.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a8032e2b03ff6fd7e4f2e0a199aad49f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["'Bison', 'Nunito'"],"urls":["/fonts/stylesheet.css"]}},
    },{
      plugin: require('../node_modules/gatsby-background-image-es5/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156034026-1","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
